<template>
  <section class="page-container">
    
    <div v-if="!isCategorySelected"><cms-top v-if="window.location.href.indexOf('searchTerm')===-1"></cms-top></div>
    <div class="container-wrapper">
      <product-list :show-pagination="true" :co-order="false"></product-list>
    </div>
    <div v-if="!isCategorySelected"><cms-bottom></cms-bottom></div>
  </section>
</template>

<script>
  import ProductList from "@/components/Product/List";
  import CmsTop from "@/components/Cms/Top";
  import CmsBottom from "@/components/Cms/Bottom";
  import { onMounted, computed } from "vue";
  import { useStore } from "vuex";
  import { useI18n } from "vue-i18n";
  import { useRoute } from "vue-router";

  export default {
    name: "Home",
    components: {
      ProductList,
      CmsTop,
      CmsBottom
    },
    setup() {
      const { t } = useI18n();
      const store = useStore();
      const route = useRoute();

      const isCategorySelected = computed(() => {
        return (route.path.indexOf("kategorie") >= 0);
      });

      onMounted(() => {
        // Fetch the teasers in this component so that they can be stored in the store before being used by other components like CmsTop and ProductList
        // Otherwise we would have to send the request separately in each component that makes use of the teasers

        // if (route?.meta?.pageType !== "category") {
        //   store.dispatch("cms/getMainPageContents");
        // }
      });

      return {
        t,
        isCategorySelected
      };
    }
  };
</script>
<style lang="postcss" scoped>
  .page-container {
    @apply w-full bg-blue-lightest;
  }
  .container-wrapper {
    @apply w-11/12 ml-auto mr-auto justify-center items-center text-gray-dark;
    grid-area: main;
  }
  @media (min-width: 992px) {
    .container-wrapper {
      @apply w-9/12;
      width: 960px;
    }
  }
</style>
