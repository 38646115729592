<template>
      <section class="cms-teasers hidden md:block">
            <div class="cms-teasers__container lg:grid-flow-row md:grid-flow-row grid-flow-row-dense">
                  <div class="cms-teasers__image cms-teasers__image--three" v-for="image in cms" :key="image.id">
                        <a :href="`${image.url}`" target="_self" :title="`${image.url}`">
                              <img class="mx-auto my-0" :alt="`${image.imageName}`" :src="`${buildImage(image.imageName)}`" />
                        </a>
                  </div>
            </div>
      </section>
</template>

<script>
      import { computed } from "@vue/reactivity";
      import { useStore } from "vuex";
      import constants from "@/helpers/constants";

      export default {
            name: "Bottom",
            components: {},
            setup() {
                  const store = useStore();
                  const buildImage = (image) => {
                        return image.indexOf("http") === -1 ? constants.FE_URL + constants.IMAGE_DIR.CMS + image : image;
                  };
                  return {
                        buildImage,
                        cms: computed(() => {
                              let cms = store.getters["cms/getMainPageContents"];
                              cms = Object.values(cms);
                              if (cms && cms.length > 0) {
                                    return cms.filter(function (data) {
                                          return ["BOTTOM1", "BOTTOM2"].includes(data.tag);
                                    });
                              }
                              return [];
                        })
                  };
            }
      };
</script>
