<template>
      <section class="cms-teasers block">
            <div class="cms-teasers__container lg:grid-flow-row md:grid-flow-row lg:grid-cols-2 md:grid-cols-2 grid-flow-row-dense">
                  <div class="cms-teasers__image cms-teasers__image--one" v-for="image in cms" :key="image.id">
                        <a :href="`${image.url}`" target="_self" :title="`${image.url}`">
                              <img :alt="`${image.imageName}`" :src="`${buildImage(image.imageName)}`" />
                        </a>
                  </div>
            </div>
      </section>
</template>

<script>
      import { useStore } from "vuex";
      import { computed, defineAsyncComponent } from "vue";
      import constants from "@/helpers/constants";
      // const appImage = defineAsyncComponent({
      //   loader: () => import("@/components/Image/Image"),
      // });
      export default {
            name: "Top",
            components: {},
            setup() {
                  const store = useStore();
                  const buildImage = (image) => {
                        return image.indexOf("http") === -1 ? constants.FE_URL + constants.IMAGE_DIR.CMS + image : image;
                  };
                  return {
                        buildImage,
                        cms: computed(() => {
                              let cms = store.getters["cms/getMainPageContents"];
                              cms = Object.values(cms);

                              if (cms && cms.length > 0) {
                                    return cms.filter(function (data) {
                                          if (window.matchMedia("(max-width: 767px)").matches) {
                                                return ["TOP1_MOBILE", "TOP2_MOBILE"].includes(data.tag);
                                          } else {
                                                return ["TOP1", "TOP2"].includes(data.tag);
                                          }
                                    });
                              }

                              return [];
                        })
                  };
            }
      };
</script>
